import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import AuthGuard from "utils/route-guard/AuthGuard";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
	AppBar,
	Box,
	CssBaseline,
	Toolbar,
	useMediaQuery,
} from "@mui/material";
import AdminContainer from "ui-component/AdminContainer";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";

// assets
import { IconChevronRight } from "@tabler/icons-react";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		...theme.typography.mainContent,
		borderRadius: 0,
		transition: theme.transitions.create(
			"margin",
			open
				? {
						easing: theme.transitions.easing.easeOut,
						duration: theme.transitions.duration.enteringScreen,
				  }
				: {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
				  }
		),
		[theme.breakpoints.up("md")]: {
			marginRight: open ? 0 : -drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
		},
		[theme.breakpoints.down("md")]: {
			marginRight: "16px",
			width: `calc(100% - ${drawerWidth}px)`,
			padding: "16px",
		},
		[theme.breakpoints.down("sm")]: {
			marginRight: "10px",
			width: `calc(100% - ${drawerWidth}px)`,
			padding: "16px",
			marginLeft: "10px",
		},
	})
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	// Handle left drawer
	const leftDrawerOpened = useSelector((state) => state.customization.opened);
	const dispatch = useDispatch();
	const handleLeftDrawerToggle = () => {
		dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
	};

	return (
		<Box
			sx={{
				display: "flex",
			}}
		>
			<CssBaseline />
			{/* header */}
			<AppBar
				enableColorOnDark
				position="fixed"
				color="inherit"
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.header,
					transition: leftDrawerOpened
						? theme.transitions.create("width")
						: "none",
				}}
			>
				<Toolbar>
					<Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
				</Toolbar>
			</AppBar>

			{/* drawer */}
			<Sidebar
				drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
				drawerToggle={handleLeftDrawerToggle}
			/>

			{/* main content */}
			<Main theme={theme} open={leftDrawerOpened}>
				{/* breadcrumb */}
				<Breadcrumbs
					separator={IconChevronRight}
					navigation={navigation}
					icon
					title
					rightAlign
				/>
				<AuthGuard>
					<AdminContainer>
						<Outlet />
					</AdminContainer>
				</AuthGuard>
			</Main>
		</Box>
	);
};

export default MainLayout;
