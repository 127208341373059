export default function componentStyleOverrides(theme) {
	const bgColor =
		theme.mode === "dark" ? theme.backgroundDefault : theme.colors?.grey50;
	return {
		MuiAlert: {
			styleOverrides: {
				root: {
					"& .MuiAlert-icon": {
						marginLeft: "12px",
						marginRight: "0",
					},
				},
			},
		},
		MuiButtonGroup: {
			styleOverrides: {
				root: {
					"& .MuiButtonGroup-firstButton": {
						borderTopLeftRadius: "0",
						borderLeftColor: "inherit",
						borderRightColor: "inherit",
						borderTopRightRadius: "inherit",
						borderBottomRightRadius: "inherit",
						borderBottomLeftRadius: "0",
					},
					"& .MuiButtonGroup-lastButton": {
						borderTopLeftRadius: "inherit",
						borderRightColor: "transparent",
						borderTopRightRadius: "0",
						borderBottomRightRadius: "0",
						borderBottomLeftRadius: "inherit",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					borderRadius: "4px",
					"&.Mui-disabled": {
						color: theme.colors?.grey600,
					},
					"& .MuiButton-startIcon": {
						marginLeft: "8px",
						marginRight: "-4px",
					},
				},
			},
		},
		//MuiAutocomplete-popper MuiPopover-root
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-root": {
						paddingLeft: "65px !important",
						paddingRight: "9px !important",
					},
					"& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
						right: "unset",
						left: "9px",
					},
				},
				popper: {
					// inherit MuiPopover-root
					boxShadow:
						"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
					borderRadius: "12px",
					color: "#364152",
				},
				listbox: {
					// inherit MuiPopover-root
					padding: "0px",
					paddingTop: "8px",
					paddingBottom: "8px",
				},
				option: {
					fontSize: "16px",
					fontWeight: "400",
					lineHeight: "1.334em",
					alignItems: "center",
					paddingTop: "6px",
					paddingBottom: "6px",
					paddingLeft: "16px",
					paddingRight: "16px",
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					"&:hover": {
						backgroundColor: theme.colors?.grey200,
					},
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
				rounded: {
					borderRadius: `${theme?.customization?.borderRadius}px`,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					color: theme.colors?.textDark,
					padding: "24px",
				},
				title: {
					fontSize: "1.125rem",
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: "24px",
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: "24px",
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					paddingTop: "10px",
					paddingBottom: "10px",
					"&.Mui-selected": {
						color: theme.menuSelected,
						backgroundColor: theme.menuSelectedBack,
						"&:hover": {
							backgroundColor: theme.menuSelectedBack,
						},
						"& .MuiListItemIcon-root": {
							color: theme.menuSelected,
						},
					},
					"&:hover": {
						backgroundColor: theme.menuSelectedBack,
						color: theme.menuSelected,
						"& .MuiListItemIcon-root": {
							color: theme.menuSelected,
						},
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					minWidth: "36px",
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					textAlign: "start",
				},
				primary: {
					color: theme.textDark,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					color: theme.textDark,
					"&::placeholder": {
						color: theme.darkTextSecondary,
						fontSize: "0.875rem",
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					transformOrigin: "top right",
					right: "0",
					left: "unset",
					transform: "translate(-14px, 16px) scale(1)",

					"&[data-shrink='true']": {
						transform: "translate(-14px, -9px) scale(0.75)",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					background: bgColor,
					borderRadius: `${theme?.customization?.borderRadius}px`,
					"& .MuiOutlinedInput-notchedOutline": {
						textAlign: "start",
						borderColor: theme.colors?.grey400,
					},
					"&:hover $notchedOutline": {
						borderColor: theme.colors?.primaryLight,
					},
					"&.MuiInputBase-multiline": {
						padding: 1,
					},
				},
				input: {
					fontWeight: 500,
					background: bgColor,
					padding: "15.5px 14px",
					borderRadius: `${theme?.customization?.borderRadius}px`,

					"&.MuiInputBase-inputSizeSmall": {
						padding: "10px 14px",
						"&.MuiInputBase-inputAdornedStart": {
							paddingLeft: 0,
						},
					},
				},
				inputAdornedStart: {
					paddingLeft: 4,
				},
				notchedOutline: {
					borderRadius: `${theme?.customization?.borderRadius}px`,
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						color: theme.colors?.grey300,
					},
				},
				mark: {
					backgroundColor: theme.paper,
					width: "4px",
				},
				valueLabel: {
					color: theme?.colors?.primaryLight,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: theme.divider,
					opacity: 1,
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: theme.colors?.primaryDark,
					background: theme.colors?.primary200,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					"&.MuiChip-deletable .MuiChip-deleteIcon": {
						color: "inherit",
						margin: "0 -6px 0 5px !important",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid " + theme.tableBorderBottom,
					textAlign: "center",
				},
				head: {
					color: theme.darkTextSecondary,
					backgroundColor: theme.headBackgroundColor,
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: theme.headBackgroundColor,
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: theme.colors.paper,
					background: theme.colors?.grey700,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					textAlign: "start",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
      .apexcharts-title-text {
          fill: ${theme.textDark} !important
        }
      .apexcharts-text {
        fill: ${theme.textDark} !important
      }
      .apexcharts-legend-text {
        color: ${theme.textDark} !important
      }
      .apexcharts-menu {
        background: ${theme.backgroundDefault} !important
      }
      .apexcharts-gridline, .apexcharts-xaxistooltip-background, .apexcharts-yaxistooltip-background {
        stroke: ${theme.divider} !important;
      }
      `,
		},
	};
}
